<template>
  <div class="loginPage">
    <HeadInfo :is-login="true" />
    <div class="loginMain">
      <div class="loginForget">
        <div>
          <img
            src="../../assets/img/forgotuser.png"
            width="216"
            alt="forgotuser"
            :style="visibility"
          />
        </div>
        <div>
          <div class="loginTitle">
            {{ $g("forgetUsername") }}
          </div>
          <div class="loginDescription" v-html="description" />
        </div>
      </div>
      <div class="loginContent">
        <k-alert-msg ref="lblMsg" />
        <KValidationObserver ref="observer">
          <k-form-group
            label-class="require-mark"
            label-suffix="firstName"
            label-for="noneBind"
            label-align-md="right"
            label-cols-md="3"
            content-cols-lg="6"
            content-cols-md="7"
          >
            <KValidationProvider
              :rules="{ required: true, max: 200 }"
              v-slot="{ errors }"
              slim
              name="firstName"
            >
              <k-alert :show="errors.length > 0" variant="warning">
                {{ errors[0] }}
              </k-alert>
              <k-form-input
                id="firstName"
                v-model="form.firstName"
                :aria-label="$g('firstName')"
              />
            </KValidationProvider>
          </k-form-group>
          <k-form-group
            label-suffix="middleName"
            label-for="noneBind"
            label-align-md="right"
            label-cols-md="3"
            content-cols-lg="6"
            content-cols-md="7"
          >
            <KValidationProvider
              :rules="{ max: 200 }"
              v-slot="{ errors }"
              slim
              name="middleName"
            >
              <k-alert :show="errors.length > 0" variant="warning">
                {{ errors[0] }}
              </k-alert>
              <k-form-input
                id="middleName"
                v-model="form.middleName"
                :aria-label="$g('middleName')"
              />
            </KValidationProvider>
          </k-form-group>
          <k-form-group
            label-class="require-mark"
            label-suffix="lastName"
            label-for="noneBind"
            label-align-md="right"
            label-cols-md="3"
            content-cols-lg="6"
            content-cols-md="7"
          >
            <KValidationProvider
              :rules="{ required: true, max: 200 }"
              v-slot="{ errors }"
              slim
              label-suffix="lastName"
              name="lastName"
            >
              <k-alert :show="errors.length > 0" variant="warning">
                {{ errors[0] }}
              </k-alert>
              <k-form-input
                id="lastName"
                v-model="form.lastName"
                :aria-label="$g('lastName')"
              />
            </KValidationProvider>
          </k-form-group>
          <k-form-group
            label-class="require-mark"
            label-suffix="email"
            label-for="noneBind"
            label-align-md="right"
            label-cols-md="3"
            content-cols-lg="6"
            content-cols-md="7"
          >
            <KValidationProvider
              :rules="{ required: true, max: 256, email: true }"
              v-slot="{ errors }"
              slim
              name="email"
              label-suffix="email"
            >
              <k-alert :show="errors.length > 0" variant="warning">
                {{ errors[0] }}
              </k-alert>
              <k-form-input
                id="email"
                v-model="form.email"
                :aria-label="$g('email')"
              />
            </KValidationProvider>
          </k-form-group>

          <k-form-group
            label-for="noneBind"
            label-align-md="right"
            label-cols-md="3"
            content-cols-lg="6"
            content-cols-md="7"
          >
            <div class="alert alert-warning" v-if="isManMachine">
              {{ $g("validate.required") }}
            </div>
            <ReCaptcha
              @validate="validate"
              @expired="resetCaptcha"
              style="margin-bottom: 25px"
            />
          </k-form-group>
        </KValidationObserver>

        <k-form-group
          label-for="noneBind"
          label-align-md="right"
          label-cols-md="3"
          content-cols-lg="6"
          content-cols-md="7"
          class="wapBtnCenter"
        >
          <k-button type="button" @click="send"> send </k-button>
        </k-form-group>
      </div>
    </div>
    <FooterInfo />
  </div>
</template>
<script>
import { sendForgetUsernameEmail, getPageDesc } from "@/api/login";
import HeadInfo from "@/layout/headInfo";
import FooterInfo from "@/layout/footerInfo";
import {
  storeage,
  ReCaptcha,
  repResult,
  getCurPageConfigAndDefaultPageConfigbyRoutePath,
  getConfigVal,
} from "fe-infrastractures";

export default {
  components: {
    HeadInfo,
    FooterInfo,
    ReCaptcha,
  },
  data() {
    let curPageConfigAndDefaultPageConfig =
      getCurPageConfigAndDefaultPageConfigbyRoutePath(
        "workerPortal",
        this.$route.path
      );
    let visibility = getConfigVal(
      curPageConfigAndDefaultPageConfig,
      "showPic",
      "vVisibility"
    );
    return {
      visibility,
      isManMachine: false,
      isValidateRecaptcha: false,
      description: "",
      pageName: "ForgotUsername-SendEmail",
      form: {
        firstName: null,
        middleName: null,
        lastName: null,
        email: null,
      },
      localEnv: storeage.getSystemGeneralInfo()?.isLocal,
    };
  },
  methods: {
    validate(success) {
      this.isValidateRecaptcha = success;
      if (this.localEnv === false) {
        this.isManMachine = !this.isValidateRecaptcha;
      }
    },
    resetCaptcha() {
      this.isValidateRecaptcha = false;
    },
    async send() {
      const isValid = await this.validateBeforeSave();
      if (isValid) {
        sendForgetUsernameEmail(this.form).then((resp) => {
          if (resp.data.status == repResult.success) {
            this.$router.push({
              name: "submit-success",
              query: { pageName: "ForgetUsername-SubmitSuccessful" },
            });
          } else {
            this.$refs.lblMsg.message(repResult.faild, resp.message);
          }
        });
      }
    },
    async validateBeforeSave() {
      var isValid = await this.$refs.observer.validate();
      if (this.localEnv === false) {
        this.isManMachine = !this.isValidateRecaptcha;
        isValid = isValid && this.isValidateRecaptcha;
      }
      return isValid;
    },
  },
  mounted() {
    getPageDesc(this.pageName).then((resp) => {
      this.description = resp.data.description;
    });
  },
};
</script>
